const Home = () => import("@/views/Home");
const GameDetail = () => import("@/views/GameDetail");
const SweetVote = () => import("@/views/SweetVote");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            status: "1",
        },
    },
    {
        path: "/gameDetail",
        name: "gameDetail",
        component: GameDetail,
        meta: {
            status: "1",
        },
    },
    {
        path: "/sweetVote",
        name: "sweetVote",
        component: SweetVote,
        meta: {
            status: "1",
        },
    },
];
export default routes;
